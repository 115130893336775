import React from 'react';
import {
  ConditionsCollapsible,
  ContinuedTextListModule,
  FeaturedArticles,
  Hero,
  Layout,
  Module,
  SingleQuote,
  StatisticStructuredBox,
  Structured
} from '../../redesign';
import { CliniciansHalfAndHalf } from '../../redesign/pre-built-components';
import structuredContent from '../../redesign/content/adrenal-fatigue/structured-understand';
import heroContent from '../../redesign/content/adrenal-fatigue/hormonal-hero.json';
import singleStatStructuredContent from '../../redesign/content/adrenal-fatigue/single-stat-structure.json';
import symptomsListContent from '../../redesign/content/adrenal-fatigue/symptoms-list.json';
import doctorsSuccessfullyTreatContent from '../../redesign/content/adrenal-fatigue/doctors-successfully-treat.json';
import quoteCarouselContent from '../../redesign/content/adrenal-fatigue/quote-carousel.json';
import blogPostContent from '../../redesign/content/adrenal-fatigue/blog-post.json';
import { default as SEO } from '../../components/SEO';

export default function AdrenalFatigue() {
  return (
    <Layout>
      <SEO
        pageTitle="Adrenal Fatigue Natural Treatment | Holistic Care | Parsley Health"
        pageDescription="At Parsley Health, we take HPA axis dysfunction seriously. We treat adrenal fatigue naturally. Learn more about our holistic treatment, and feel better."
      />
      <Module>
        <Hero {...heroContent} />
      </Module>
      <Module marginTop="lg">
        <StatisticStructuredBox {...singleStatStructuredContent} />
      </Module>
      <Module>
        <ContinuedTextListModule {...symptomsListContent} />
      </Module>
      <Module>
        <ConditionsCollapsible
          heading="The HPA axis regulates your stress response."
          {...doctorsSuccessfullyTreatContent}
        />
      </Module>
      <Module>
        <Structured {...structuredContent} />
      </Module>
      <Module>
        <SingleQuote {...quoteCarouselContent} />
      </Module>
      <Module>
        <CliniciansHalfAndHalf
          ctaTrackingLabel="Meet Our Clinicians button on the Adrenal Fatigue page - Stress-free online holistic care module"
          eyebrow="How to fight chronic stress."
        />
      </Module>
      <Module>
        <FeaturedArticles {...blogPostContent} />
      </Module>
    </Layout>
  );
}
