import React from 'react';
import { IllustrativeIcon } from '../../atoms/icons/illustrative-icon/illustrative-icon';
import { StructuredProps } from '../../types';

const Data: StructuredProps = {
  alignment: 'center',
  heading: 'Rebalance your HPA axis and start healing.',
  items: [
    {
      id: 'health-history',
      heading: 'We dive deep into your health history.',
      description:
        'We spend the time it takes to learn all about your medical history, your lifestyle, and your major stressors—which often include work, family, and other health conditions.',
      illustration: <IllustrativeIcon name="personal" />
    },
    {
      id: 'advanced-diagnostics',
      heading: "Advanced diagnostics many doctors don't do.",
      description:
        'We make an HPA axis dysfunction diagnosis by measuring your salivary and serum cortisol levels at different times of day.',
      illustration: <IllustrativeIcon name="testing" />
    },
    {
      id: 'dedicated-support',
      heading: 'Get the dedicated support you need.',
      description:
        'Your personalized care plan will re-establish cortisol balance by supporting your adrenal health. Your Care Team will make recommendations for stress management, supplements and herbs, better sleep hygiene, and optimized nutrition, supporting you every step of the way.',
      illustration: <IllustrativeIcon name="teamCollab" />
    }
  ]
};

export default Data;
